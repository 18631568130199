<!-- 管理员列表 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem; margin-bottom: 0!important;">
		<el-tabs v-model="preUrl" @tab-click="getList">
			<!-- 管理员列表 -->
			<el-tab-pane label="管理员列表" name="manager">
				<div class="d-flex align-items-center">
					<!-- 添加管理员 -->
					<el-button type="primary" size="mini" @click="openDialog('manager')">添加管理员</el-button>

					<!-- 搜索 -->
					<div class="ml-auto" d-flex>
						<el-input v-model="keyword" style="width: 200px;" size="mini" placeholder="请输入用户名"></el-input>
						<el-button type="info" size="mini" class="ml-2" @click="getList">搜索</el-button>
					</div>*
				</div>

				<el-table border class="mt-3" :data="managerList" style="width: 100%">
					<!-- 用户头像 -->
					<el-table-column align="center" label="用户头像">
						<template slot-scope="scope">
							<el-avatar size="large" :src="scope.row.avatar"></el-avatar>
						</template>
					</el-table-column>

					<!-- 用户名 -->
					<el-table-column align="center" label="商家名称" prop="name">
					</el-table-column>

					<!-- 所属管理员 -->
					<el-table-column align="center" label="所属管理员">
						<template slot-scope="scope">
							{{scope.row.role.name}}
						</template>
					</el-table-column>

					<!-- 状态 -->
					<el-table-column prop="status" align="center" label="状态">
						<template slot-scope="scope">
							<el-button :type="scope.row.status ? 'success' : 'danger'" size="mini" plain @click="changeStatus(scope.row)">{{scope.row.status ? '启用' : '禁用'}}</el-button>
						</template>
					</el-table-column>

					<!-- 操作 -->
					<el-table-column align="center" label="操作" width="150">
						<template slot-scope="scope">
							<el-button type="text" size="mini" @click="openDialog('manager',scope.row)">修改</el-button>
							<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>

			<!-- 角色列表 -->
			<el-tab-pane label="角色列表" name="role">
				<div class="d-flex align-items-center">
					<!-- 添加角色 -->
					<el-button type="primary" size="mini" v-auth="'添加角色'" @click="openDialog('role')">添加角色</el-button>
				</div>

				<el-table border class="mt-3" :data="roleList" style="width: 100%">
					<!-- 角色名称 -->
					<el-table-column align="center" label="角色名称" prop="name">
					</el-table-column>

					<!-- 角色描述 -->
					<el-table-column align="center" label="角色描述" prop="desc">
					</el-table-column>

					<!-- 状态 -->
					<el-table-column prop="status" align="center" label="状态">
						<template slot-scope="scope">
							<el-button :type="scope.row.status ? 'success' : 'danger'" size="mini" @click="changeStatus(scope.row)" plain>{{scope.row.status ? '启用' : '禁用'}}</el-button>
						</template>
					</el-table-column>

					<!-- 操作 -->
					<el-table-column align="center" label="操作" width="250">
						<template slot-scope="scope">
							<el-button type="text" size="mini" @click="openDialog('role',scope.row)">修改</el-button>
							<el-button type="text" size="mini" @click="openDrawer(scope.row)">配置权限</el-button>
							<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>

			<!-- 权限列表 -->
			<el-tab-pane label="权限列表" name="rule">

				<!-- 添加规则 -->
				<div class="d-flex align-items-center">
					<el-button type="primary" size="mini" v-auth="'添加规则'" @click="openDialog('rule')">添加规则</el-button>
				</div>

				<!-- 树状分级图 -->
				<el-tree :data="ruleList" :props="defaultProps" @node-click="handleNodeClick" default-expand-all
				 :expand-on-click-node="false">

					<span class="custom-tree-node" slot-scope="{ node, data }">
						<div>
							<span v-if="data.menu" :class="data.icon ? data.icon : 'el-icon-star-off'" class="mr-2 text-muted"></span>
							<small v-else class="mr-2 text-muted">{{data.method}}</small>
							<span>{{ data.name }}</span>
							<span v-if="data.menu" class="badge ml-2">{{data.frontpath}}</span>
							<span v-else class="badge badge-secondary ml-2">{{data.condition}}</span>
						</div>
						<span>
							<!-- 显示按钮 -->
							<el-button :type="data.status ? 'success' : 'danger'" size="mini" @click="changeStatus(data)" plain>{{data.status ? '启用' : '禁用'}}</el-button>

							<!-- 增加按钮 v-auth权限筛选 -->
							<el-button type="text" size="mini" @click="openDialog('rule',data.id)" v-auth="'添加规则'">增加</el-button>

							<!-- 修改按钮 -->
							<el-button type="text" size="mini" @click="openDialog('rule',data)">修改</el-button>

							<!-- 删除按钮 -->
							<el-button type="text" size="mini" @click="deleteItem(data)">删除</el-button>
						</span>
					</span>
				</el-tree>
			</el-tab-pane>
		</el-tabs>


		<div style="height: 60px;"></div>
		<!-- 底部分页 -->
		<el-footer v-if="preUrl != 'rule'" class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0; left: 200px; right: 0;z-index: 100;">
			<div style="flex: 1;" class="px-2">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.current"
				 :page-sizes="page.sizes" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
				</el-pagination>
			</div>
		</el-footer>

		<!-- 模态框 -->
		<el-dialog title="提示" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
			<!-- 添加/修改管理员 -->
			<el-form v-if="preUrl ==='manager' " ref="form" :model="form.manager" label-width="80px">

				<!-- 用户名 -->
				<el-form-item label="用户名" prop="username">
					<el-input v-model="form.manager.username" placeholder="用户名" size="mini" style="width: 25%;"></el-input>
				</el-form-item>

				<!-- 密码 -->
				<el-form-item label="密码" prop="password">
					<el-input v-model="form.manager.password" placeholder="密码" size="mini" style="width: 25%;"></el-input>
				</el-form-item>
				
				<!-- 用户名 -->
				<el-form-item label="商家名称" prop="username">
					<el-input v-model="form.manager.name" placeholder="商家名称" size="mini" style="width: 25%;"></el-input>
				</el-form-item>
				
				<!-- 头像 -->
				<el-form-item label="头像">
					<div>
						<span v-if="!form.manager.avatar" class="btn btn-light border mr-2" @click="chooseImage">
							<i class="el-icon-plus"></i>
						</span>
						<img v-else :src="form.manager.avatar" class="rounded" style="width: 45px;height: 45px;cursor: pointer;" @click="chooseImage" />
					</div>
				</el-form-item>

				<!-- 所属角色 -->
				<el-form-item label="所属角色">
					<el-select v-model="form.manager.role_id" placeholder="请选择所属角色" size="mini">
						<el-option v-for="(item,index) in roleOptions" :key="index" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<!-- 状态 -->
				<el-form-item label="状态">
					<el-radio-group v-model="form.manager.status" size="mini">
						<el-radio :label="1" border>启用</el-radio>
						<el-radio :label="0" border>禁用</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>

			<!-- 添加/修改角色 -->
			<el-form v-if="preUrl ==='role' " ref="form" :model="form.role" label-width="80px">

				<!-- 角色名称 -->
				<el-form-item label="角色名称" prop="name">
					<el-input v-model="form.role.name" placeholder="角色名称" size="mini" style="width: 25%;"></el-input>
				</el-form-item>

				<!-- 角色描述 -->
				<el-form-item label="角色描述" prop="desc">
					<el-input type="textarea" v-model="form.role.desc" placeholder="角色描述" size="mini"></el-input>
				</el-form-item>

				<!-- 状态 -->
				<el-form-item label="状态">
					<el-radio-group v-model="form.role.status" size="mini">
						<el-radio :label="1" border>启用</el-radio>
						<el-radio :label="0" border>禁用</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>

			<!-- 添加/修改规则 -->
			<el-form v-if="preUrl ==='rule' " ref="form" :model="form.rule" label-width="100px">

				<!-- 上级规则 -->
				<el-form-item label="上级规则">
					<el-select v-model="form.rule.rule_id" placeholder="请选择上级规则" size="mini">
						<el-option label="顶级规则" :value="0"></el-option>
						<el-option v-for="(item,index) in ruleOptions" :key="index" :label="item | tree" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<!-- 菜单/规则 -->
				<el-form-item label="菜单/规则">
					<el-radio-group v-model="form.rule.menu" size="mini">
						<el-radio :label="1" border>菜单</el-radio>
						<el-radio :label="0" border>规则</el-radio>
					</el-radio-group>
				</el-form-item>

				<!-- 名称 -->
				<el-form-item label="名称">
					<el-input v-model="form.rule.name" placeholder="名称" size="mini" style="width: 25%;"></el-input>
				</el-form-item>

				<!-- 图标 -->
				<el-form-item label="图标" v-if="form.rule.menu">
					<el-input v-model="form.rule.icon" placeholder="图标" size="mini"></el-input>
				</el-form-item>

				<!-- 前端路由路径 -->
				<el-form-item label="前端路由路径" v-if="form.rule.menu && form.rule.rule_id > 0">
					<el-input v-model="form.rule.frontpath" placeholder="前端路由路径" size="mini"></el-input>
				</el-form-item>

				<!-- 前台路由别名 -->
				<el-form-item label="前台路由别名" v-if="form.rule.menu">
					<el-input v-model="form.rule.desc" placeholder="前台路由别名" size="mini"></el-input>
				</el-form-item>

				<!-- 后端规则 -->
				<el-form-item label="后端规则" v-if="!form.rule.menu">
					<el-input v-model="form.rule.condition" placeholder="后端规则" size="mini"></el-input>
				</el-form-item>

				<!-- 请求方式 -->
				<el-form-item label="请求方式" v-if="!form.rule.menu">
					<el-select v-model="form.rule.method" placeholder="请选择请求方式" size="mini">
						<el-option label="GET" value="GET"></el-option>
						<el-option label="POST" value="POST"></el-option>
						<el-option label="PUT" value="PUT"></el-option>
						<el-option label="DELETE" value="DELETE"></el-option>
					</el-select>
				</el-form-item>

				<!-- 状态 -->
				<el-form-item label="状态">
					<el-radio-group v-model="form.rule.status" size="mini">
						<el-radio :label="1" border>启用</el-radio>
						<el-radio :label="0" border>禁用</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 配置权限 -->
		<el-drawer title="配置权限" :visible.sync="drawer" direction="rtl" :before-close="handleClose" size="40%">
			<div style="position: absolute;top:52px;left: 0;right: 0;bottom: 0;">
				<!-- 内容 -->
				<div style="position: absolute;top: 0;left: 0;right: 0;bottom: 60px;overflow-y: auto;">
					<el-tree :data="ruleList" show-checkbox node-key="id" default-expand-all :default-checked-keys="checkedKeys"
					 :props="defaultProps" :check-strictly="true" @check="check">
					</el-tree>
				</div>
				
				<!-- 底部按钮 -->
				<div style="height: 60px;position: absolute;bottom: 0;right: 0;left: 0;" class="border d-flex align-items-center px-3 bg-white">
					<el-button @click="drawer = false">取消</el-button>
					<el-button type="primary" @click="submitRules">确定</el-button>
				</div>
			</div>
		</el-drawer>

	</div>
</template>

<script>
	import common from "@/common/mixins/common.js" // 引入 全局公共样式
	export default {
		inject: ['app', 'layout'],
		mixins: [common], // 引入mixins里的common.js
		data() {
			return {
				keyword: "",
				preUrl: "manager", //api
				managerList: [], //管理员列表

				roleList: [], //角色列表

				ruleList: [], //权限列表
				defaultProps: {
					children: 'child',
					label: 'name'
				},

				// 模态框属性
				dialogVisible: false,
				dialogType: "manager",
				roleOptions: [],
				ruleOptions: [],
				dialogId: 0,

				form: {
					// 表单值
					manager: {
						username: "", //用户名
						password: "", //密码
						avatar: "", //头像
						role_id: "", //角色id
						status: 1 //是否启用
					},
					role: {
						name: "",
						desc: "",
						status: 1
					},
					rule: {
						menu: 0, //是否是菜单，0否1是
						rule_id: 0, //上级权限id
						name: "", //权限名称
						status: 1, //是否启用
						frontpath: "", //对应前端路由路径（menu为1且rule_id为0时必填）
						desc: "", //对应前端路由路径别名（menu为1时必填）
						condition: "", //后端规则，例如admin.Rule/index, app/controller/admin目录Rule控制器的index方法（menu为0时必填）
						icon: "", //element-ui图标
						method: "GET", //请求方式：GET/POST/PUT/DELETE（menu为0时必填）
						order: 50 //
					}
				},

				drawer: false,
				drawerId: 0,
				checkedKeys: []
			}
		},
		//过滤器
		filters: {
			tree(item) { //过滤 添加 上级规则
				if (item.level == 0) {
					return item.name
				}
				let str = ''
				for (let i = 0; i < item.level; i++) {
					str += i == 0 ? '|--' : '--'
				}
				return str + ' ' + item.name;
			}
		},
		methods: {
			// 打开弹出层
			openDialog(type, item = false) {
				// 确认类型 （添加管理员、添加角色、添加规则）
				this.dialogType = type
				// 初始化表单
				switch (type) {
					// 初始化添加管理员
					case 'manager':
						if (!item) {
							this.form.manager = {
								username: "",
								password: "",
								avatar: "",
								role_id: "",
								status: 1
							}
							this.dialogId = 0
						} else {
							this.form.manager = {
								username: item.username,
								password: item.password,
								avatar: item.avatar,
								role_id: item.role_id,
								status: item.status,
							}
							this.dialogId = item.id
						}
						break;
						// 初始化添加角色
					case 'role':
						if (!item) {
							this.form.role = {
								name: "",
								desc: "",
								status: 1
							}
							this.dialogId = 0
						} else {
							this.form.role = {
								name: item.name,
								desc: item.desc,
								status: item.status,
							}
							this.dialogId = item.id
						}
						break;
						// 初始化添加规则
					case 'rule':
						if (!item || typeof item === 'number') {
							this.form.rule = {
								menu: 0,
								rule_id: 0,
								name: "",
								status: 1,
								frontpath: "",
								desc: "",
								condition: "",
								icon: "",
								method: "GET",
								order: 50
							}
							this.form.rule.rule_id = item || 0
							this.dialogId = 0
						} else {
							this.form.rule = {
								menu: item.menu,
								rule_id: item.rule_id,
								name: item.name,
								status: item.status,
								frontpath: item.frontpath,
								desc: item.desc,
								condition: item.condition,
								icon: item.icon,
								method: item.method,
								order: 50
							}
							this.dialogId = item.id
						}
						break;
				}
				// 显示弹出层
				this.dialogVisible = true
			},
			// 模态框关闭判断 防止点到空白的地方退出
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			// 获取请求列表分页url
			getListUrl() {
				if (this.preUrl === 'manager') {
					return `/admin/${this.preUrl}/${this.page.current}?limit=${this.page.size}&keyword=${this.keyword}`
				}
				return `/admin/${this.preUrl}/${this.page.current}?limit=${this.page.size}`
			},
			// 处理列表结果
			getListResult(e) {
				console.log(e);
				switch (this.preUrl) {
					case 'manager': //获得管理员列表
						this.managerList = e.list
						this.roleOptions = e.role
						break;
					case 'role': //获得角色列表
						this.roleList = e.list
						break;
					case 'rule': //获得规则列表
						this.ruleList = e.list
						this.ruleOptions = e.rules
						break;
				}
			},
			// 提交
			submit() {
				this.addOrEdit(this.form[this.preUrl], this.dialogId); //调用common.js里的addOrEdit添加/编辑
				this.dialogVisible = false
			},
			// 监听树状图数据
			handleNodeClick(data) {
				console.log(data);
			},
			// 选择头像
			chooseImage() {
				this.app.chooseImage((res) => {
					this.form.manager.avatar = res[0].url
				}, 1)
			},
			// 打开抽屉
			openDrawer(item) {
				console.log(item);
				this.layout.showLoading()
				this.axios.get('/admin/rule/1', { //权限列表的第一页api
					token: true
				}).then(res => {
					this.drawer = true //成功之后 打开抽屉
					this.ruleList = res.data.data.list //给ruleList权限列表赋值
					this.checkedKeys = item.rules.map(item => item.id) //由id组成的一维数组（选中的返回id）
					this.drawerId = item.id
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			check(...e) {
				this.checkedKeys = e[1].checkedKeys
			},
			// 提交配置权限
			submitRules() {
				this.axios.post('/admin/role/set_rules', { //配置权限api
					id: this.drawerId,
					rule_ids: this.checkedKeys
				}, {
					token: true
				}).then(res => {
					this.drawer = false
					this.getList()
					this.$message({
						type: "success",
						message: "配置成功"
					})
				})
			}
		}
	}
</script>

<style>
</style>
